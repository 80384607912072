import React from "react";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import { Paper } from "components/ui/paper";
import { Box } from "components/ui/box";
import { EmbeddedTweet } from "external/react-tweet";
import { Container } from "./front-page/shared";
import { Typography } from "components/ui/Typography";
import { Avatar } from "components/ui/avatar";
import { stringToColor } from "utils/string-to-color";
import { dayjs } from "client-server-shared/utils/dayjs";
import { testmonialData } from "./data";
import Script from "next/script";
import EmailIcon from "@mui/icons-material/Email";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
let ids = [];
let ids2 = [];

const uniqueTestmonialData = testmonialData.filter((item) => {
  if (item.user) {
    if (ids2.includes(item.user.id_str)) {
      return false;
    }
    ids2.push(item.user.id_str);
  } else {
    if (ids2.includes(item.name)) {
      return false;
    }
    ids2.push(item.name);
  }
  return true;
});

export const ReviewMarkup = () => {
  return (
    <Script
      type="application/ld+json"
      strategy="beforeInteractive"
      id="product-reviews"
      key="product-reviews"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Product",
          name: "Junia AI Reviews",
          image: "",
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "5",
            ratingCount: "3",
            reviewCount: uniqueTestmonialData.length.toString(),
          },
          review: uniqueTestmonialData.map((item) => {
            const body = item.text || item.content;
            const authorName = item.name || item.reviewName || item.user.name;
            const type =
              item.type === "twitter"
                ? "Twitter"
                : item.type === "producthunt"
                ? "Product Hunt"
                : "Trustpilot";
            return {
              "@type": "Review",
              reviewBody: body,
              ...(item.type === "trustpilot"
                ? {
                    name: item.reviewName,
                    reviewRating: {
                      "@type": "Rating",
                      ratingValue: "5",
                    },
                  }
                : {}),
              author: { "@type": "Person", name: authorName },

              ...(item.noOrg
                ? {}
                : { publisher: { "@type": "Organization", name: type } }),
            };
          }),
        }),
      }}
    />
  );
};

const PilotLogo = () => {
  return (
    <Box
      sx={{
        height: "23px",
        width: "23px",
      }}
      component={"svg"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 799.89 761"
    >
      <path
        d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z"
        fill="#00b67a"
      />
      <path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#005128" />
    </Box>
  );
};

const ProductHuntLogo = () => {
  return (
    <Box
      sx={{
        height: "25px",
        width: "25px",
      }}
      component={"svg"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.245 26.256"
    >
      <path
        d="M26.254 13.128c0 7.253-5.875 13.128-13.128 13.128S-.003 20.382-.003 13.128 5.872 0 13.125 0s13.128 5.875 13.128 13.128"
        fill="#da552f"
      />
      <path
        d="M14.876 13.128h-3.72V9.2h3.72c1.083 0 1.97.886 1.97 1.97s-.886 1.97-1.97 1.97m0-6.564H8.53v13.128h2.626v-3.938h3.72c2.538 0 4.595-2.057 4.595-4.595s-2.057-4.595-4.595-4.595"
        fill="#fff"
      />
    </Box>
  );
};
function stringAvatar(name: string) {
  const splitted = name.split(" ");

  const firstLetter = (name.split(" ")[0][0] || "").toUpperCase();

  const secondLetter =
    splitted.length > 1 ? (name.split(" ")[1][0] || "").toUpperCase() : "";

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      splitted.length > 1 ? `${firstLetter}${secondLetter}` : firstLetter,
  };
}

const StandardCard = ({
  name,
  type,
  url,
  reviewName,
  authorImage,
  content,
  date,
  authorUrl,
  authorHandle,
}) => {
  return (
    <Paper
      component="article"
      sx={{
        padding: "0.75rem 1rem",
        borderRadius: "12px",
        height: "100%",
        border: "1px solid #e5e7eb",
        boxShadow:
          "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(169, 201, 199, 0.15) 0px 3px 8px 0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          {authorImage ? (
            <Avatar>
              <Box
                component="img"
                src={authorImage}
                alt={name || reviewName}
                sx={{
                  height: "48px",
                  width: "48px",
                  objectFit: "cover",
                }}
              />
            </Avatar>
          ) : (
            <Avatar
              {...stringAvatar(name || reviewName)}
              aria-label={name || reviewName}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component="a"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              rel="noopener noreferrer nofollow"
              target="_blank"
              href={url}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 700,
                  color: "rgb(15, 20, 25)",
                }}
              >
                {name || reviewName}
              </Typography>
            </Box>
            {authorHandle && (
              <Box
                component="a"
                sx={{
                  textDecoration: "none",
                }}
                rel="noopener noreferrer nofollow"
                target="_blank"
                href={authorUrl}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "rgb(83, 100, 113)",
                  }}
                >
                  {authorHandle}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {type === "trustpilot" ? (
          <PilotLogo />
        ) : type === "producthunt" ? (
          <ProductHuntLogo />
        ) : type === "email" ? (
          <EmailIcon sx={{ fill: "#9d9db1" }} />
        ) : type === "interview" ? null : type === "survey" ? (
          <PollOutlinedIcon sx={{ fill: "#9d216c" }} />
        ) : null}
      </Box>
      <Typography
        sx={{
          padding: "12px 0",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
          color: "rgb(15, 20, 25)",
          fontSize: "20px",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />

      {date && (
        <Box
          component="a"
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          rel="noopener noreferrer nofollow"
          target="_blank"
          href={url}
        >
          <Box
            component="time"
            sx={{
              color: "rgb(83, 100, 113)",
              fontSize: "15px",
            }}
            dateTime={date}
          >
            {dayjs(date).format("h:mm a · MMM D, YYYY")}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

const TestmonialListMobile = ({ inReviewPage }: { inReviewPage: boolean }) => {
  return (
    <Container
      sx={{
        display: {
          xs: "flex",
          sm: "flex",
          md: "none",
          lg: "none",
        },
      }}
    >
      <Masonry
        sx={{
          margin: 0,
        }}
        columns={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
        }}
        spacing={2}
        defaultHeight={450}
        defaultColumns={3}
        defaultSpacing={2}
      >
        {(inReviewPage
          ? testmonialData
          : testmonialData
              .filter((item) => item.name !== "Brocke Halery")
              .slice(0, 5)
        ).map((item, index) => {
          return (
            <Box key={index}>
              {item.type === "tweet" ? (
                <EmbeddedTweet tweet={item} />
              ) : (
                <StandardCard {...item} />
              )}
            </Box>
          );
        })}
      </Masonry>
    </Container>
  );
};

export const TestmonialList = ({ inReviewPage = false }) => {
  return (
    <>
      <TestmonialListMobile inReviewPage={inReviewPage} />
      <Container
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
          },
        }}
      >
        <Masonry
          sx={{
            margin: 0,
          }}
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
          }}
          spacing={2}
          defaultHeight={450}
          defaultColumns={3}
          defaultSpacing={2}
        >
          {(inReviewPage
            ? testmonialData
            : testmonialData
                .filter(
                  (item) =>
                    !!item.authorImage ||
                    item.type === "tweet" ||
                    item.type === "Trustpilot"
                )
                .slice(0, 13)
          ).map((item, index) => {
            return (
              <Box key={index}>
                {item.type === "tweet" ? (
                  <EmbeddedTweet tweet={item} />
                ) : (
                  <StandardCard {...item} />
                )}
              </Box>
            );
          })}
        </Masonry>
      </Container>
    </>
  );
};
